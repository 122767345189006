<template>
  <v-dialog
    v-model="isLoading"
    persistent
    max-width="360"
    :overlay="false"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title class="headline">
        <slot name="title" />
      </v-card-title>

      <v-card-text>
        <slot name="text" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <slot name="cancel" />
        <slot name="confirm" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      isLoading: 'loading',
    }),
  },
};
</script>
