<template>
  <v-navigation-drawer
    v-model="active"
    app
    overflow
    @input="closeOnOutsideClick"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Admin Panel
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ username }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list
      dense
      nav
    >
      <v-list-item
        v-for="item in availableItems"
        :key="item.title"
        link
        @click.prevent="goTo(item.linkName)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-spacer />

      <v-list-item
        link
        class="logout"
        @click="logout"
      >
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { removeSession } from '../helpers/auth';

export default {
  data: () => ({
    items: [
      {
        title: 'Scopes', icon: 'mdi-group', linkName: 'ScopeIndex', only: 'SUPER',
      },
      {
        title: 'Accounts', icon: 'mdi-account-details', linkName: 'AccountIndex', only: 'SUPER',
      },
      { title: 'Hosts', icon: 'mdi-shield-account', linkName: 'PresenterIndex' },
      { title: 'Rooms', icon: 'mdi-calendar-cursor', linkName: 'RoomIndex' },
    ],
  }),

  computed: {
    ...mapState('auth', ['accountType', 'username']),

    active: {
      get() {
        return this.$store.state.navbar.active;
      },
      set: () => {},
    },

    availableItems() {
      return this.items.filter((item) => !item.only || this.accountType === item.only);
    },
  },

  methods: {
    ...mapMutations('navbar', ['setActive']),
    ...mapMutations('auth', ['setRefreshing']),
    ...mapMutations('ScopesModule', ['setScopes']),

    goTo(linkName) {
      this.$router.push({ name: linkName });
    },

    closeOnOutsideClick(e) {
      if (!e) this.setActive(false);
    },

    logout() {
      removeSession();
      this.setScopes();
      this.setRefreshing(false);
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style>
  .logout .v-icon,
  .logout .v-list-item__content {
    color: #f56565 !important;
  }
</style>
