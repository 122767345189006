<template>
  <v-app id="dashboard">
    <LeftNavbar />

    <v-app-bar
      :clipped-left="primaryDrawer.clipped"
      app
    >
      <v-app-bar-nav-icon
        v-if="primaryDrawer.type !== 'permanent'"
        @click.stop="toggleState"
      />
      <v-toolbar-title>Admin Panel</v-toolbar-title>
    </v-app-bar>

    <v-main
      data-cy="main"
    >
      <Alert />
      <div class="pa-4">
        <slot />
      </div>
    </v-main>

    <LoadingDialog
      data-cy="loading-dialog"
    >
      <template v-slot:text>
        <p class="tw-text-lg tw-font-bold tw-text-center">
          Working on it...
        </p>
        <p class="tw-text-center">
          Please wait...
        </p>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        />
      </template>
    </LoadingDialog>

    <v-footer
      :inset="footer.inset"
      app
    >
      <span class="px-4">&copy; {{ new Date().getFullYear() }} Avantgarde</span>
    </v-footer>
  </v-app>
</template>

<script>
/* eslint-disable import/no-unresolved */
import { mapMutations } from 'vuex';
import Alert from '@/components/Alert';
import LeftNavbar from '@/components/LeftNavbar';
import LoadingDialog from '@/components/LoadingDialog';

export default {
  components: {
    Alert,
    LeftNavbar,
    LoadingDialog,
  },

  data: () => ({
    drawers: ['Default (no property)', 'Permanent', 'Temporary'],
    primaryDrawer: {
      model: null,
      type: 'default (no property)',
      clipped: false,
      floating: false,
      mini: false,
    },
    footer: {
      inset: false,
    },
    right: null,
  }),

  methods: {
    ...mapMutations('navbar', {
      toggleState: 'toggleActive',
    }),
  },
};
</script>
